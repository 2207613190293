<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-0  pt-0">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Cuentas</h3>
              <div class='d-flex' style='gap: .4rem;'>
                <vs-button v-if='cantidadDatos' @click="$router.push('CrearCuenta')">Crear Cuenta</vs-button>
                <vs-button v-else @click="saldos" :loading='waiting'>Crear los saldos</vs-button>
              </div>
            </div>
          </template>
          <div class='p-4 mb-3'>
            <template>
              <div class='mb-1'>
                <div v-for='(item, index) in tipos' :key='index'>
                  <div class="d-flex flex-row justify-content-between align-items-center animateborder"
                    style="cursor: pointer;" @click="toggleCollapse(index)">
                    <h3 class="font-weight-bold borderani" style='font-size: 20px'>{{ item }}</h3>
                    <h2 v-show="isCollapsed[index]"><i class="fa-solid fa-caret-down"></i></h2>
                    <h2 v-show="!isCollapsed[index]"><i class="fa-solid fa-caret-up"></i></h2>
                  </div>
                  <transition name="fade">
                    <div v-show="!isCollapsed[index]" class="pt-3 pb-3 row justify-content-center">
                      <Table :clase='item' />
                    </div>
                  </transition>
                  <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
                </div>
              </div>
            </template>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Swal from 'sweetalert2';
  import { core } from '../../../../../../config/pluginInit';
  import Table from '../Components/Table.vue';
  export default {
    components: {
      Table
    },
    data: () => ({
      waiting: false,
      isCollapsed: {},
      tipos: [],
      EmpresaId: null,
      cantidadDatos: null,
    }),
    async mounted() {
      const Persona = JSON.parse(localStorage.getItem('setPersonaAct'));
      this.EmpresaId = Persona.empresa.id;

      core.index();
      await this.getData();
    },
    methods: {
      toggleCollapse(index) {
        this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
      },
      async getData() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: 'DEClaseCuenta/ObtenerClaseCuenta',
          });
          let subcuentas = await this.$store.dispatch("hl_get", {
            path: `DESubCuenta/SaldosGenealesExistentes/${this.EmpresaId}`,
          });

          this.cantidadDatos = subcuentas.data;

          this.isCollapsed = res.data.map((_, index) => {
            return index === 0 ? false : true;
          });

          this.tipos = res.data.map(data => data.NombreClase);
        } catch (error) {
          this.tipos = [];
        }
      },
      async saldos() {
        try {
          this.waiting = true;
          const response = await this.$store.getters.fetchPost({
            path: `DESubCuenta/CrearSaldosGeneralesSubCuenta/${this.EmpresaId}`
          }).then((res) => res.json());
          Swal.fire({
            title: 'Procesando',
            text: 'Este proceso puede tardar algunos minutos...',
            icon: 'info',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          if (response.respuesta) {
            Swal.close(); 
            Swal.fire({
              title: 'Saldos creados',
              text: 'Se han creado los saldos',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.getData();
          } else {
            Swal.close(); 
            Swal.fire({
              title: 'Error',
              text: 'No se han guardado los saldos',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'No se han guardado los saldos',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        } finally {
          this.waiting = false;
        }
      },

    }
  }
</script>

<style>
  .header-title {
    width: 100% !important;
  }
</style>