<template>
  <card class='px-4 shadow-none w-100' style='background: transparent !important;'>
    <div class="center  ">
      <vs-checkbox @change="$refs.dataTableLotes.$emit('actualizar');" v-model="all">
        Mostrar todas las cuentas
      </vs-checkbox>

      <DataTableLotes ref="dataTableLotes" :fetch-data="fetchData" :columns="columns">
        <template v-slot:saldoCuenta="{ row }">
          {{formatearNumero(row.saldoCuenta)}}
        </template>
        <template v-slot:Id="{ row }">
          <div class="d-flex justify-content-center" style="gap: .5rem;">
            <vs-button v-b-tooltip.hover title='ver' success icon class=' '
                       @click="$router.push(`DetallesCuenta/${row.Id}`)">
              <i class="fa-solid fa-eye"></i>
            </vs-button>
            <vs-button v-b-tooltip.hover title='ver' :disabled='row.defecto' icon class=' '
                       @click="$router.push(`EditarCuenta/${row.Id}`)">
              <i class="fa-solid fa-pen"></i>
            </vs-button>
            <vs-button v-b-tooltip.hover title='ver' danger icon class=' '
                       @click.prevent='deleteCuenta(row.Id)' :disabled='row.defecto'>
              <i class="fa-solid fa-trash"></i>
            </vs-button>
          </div>
        </template>
        <template v-slot:defecto="{ row }">
          {{ row.defecto ? 'Defecto' : 'Creada' }}
        </template>
      </DataTableLotes>
    </div>
  </card>
</template>

<script>
import Swal from 'sweetalert2';
// import { debounce } from 'lodash/debounce';
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";

export default {
  components: {DataTableLotes},
  props: {
    clase: {
      type: String,
      required: true
    }
  },
  data: () => ({
    PersonaId: null,
    columns: [
      {label: "Codigo", key: "CodigoCompleto", sortable: true},
      {label: "Nombre", key: "NombreSubCuenta", sortable: true},
      {label: "Grupo", key: "nombreGrupo", sortable: true},
      {label: "Cuenta", key: "nombreCuenta", sortable: true},
      {label: "Tipo", key: "defecto", sortable: true},
      {label: "Saldo", key: "saldoCuenta", sortable: true},
      {label: "Opciones", key: "Id", sortable: false},
    ],
    all: true,
  }),
  async created() {
    const Persona = JSON.parse(localStorage.getItem('credenciales'));
    this.PersonaId = Persona.personaId;
  },
  methods: {
    async deleteCuenta(id) {
      await Swal.fire({
        title: '¿Estás seguro que deseas eliminar este Movimiento?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, borrarlo!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let response = await this.$store.dispatch("hl_delete", {
              path: `DESubCuenta/EliminarSubCuenta/${id}/personaId/${this.PersonaId}`,
            })
            if (response.respuesta) {
              this.$refs.dataTableLotes.$emit('actualizar');
              await Swal.fire("Listo!", "Cuenta eliminada correctamente.", "success");
            } else {
              await Swal.fire("Error", response.mensaje, "error");
            }
          } catch (error) {
            await Swal.fire("Error", "Hubo un problema al eliminar la cuenta.", "error");
          }
        }
      });
    },
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.PersonaId) {
        console.error("EmpresaId no está definido.");
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;
      await Promise.all([
        this.$store.dispatch('hl_get', {
          path: `DESubCuenta/consultarCantidadSubCuentasEmpresa/${this.PersonaId}?tipo=${this.clase}&filtro=${filtro}&All=${this.all}`,
        }),
        this.$store.dispatch('hl_post', {
          path: `DESubCuenta/ObtenerSubCuentasEmpresa/${this.PersonaId}?tipo=${this.clase}&All=${this.all}`,
          data: params
        })
      ]).then(([cantidadDatos, Datos]) => {
        if (cantidadDatos.respuesta) {
          total = cantidadDatos.data;
        }
        if (Datos.respuesta) {
          data = Datos.data;
        }
      });
      return {total, data};
    },
    formatearNumero(numero) {
      try {
        // Configurar opciones de formato
        const opciones = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        // Formatear el número sin símbolo
        const formato = new Intl.NumberFormat('es-CO', opciones);
        const numeroFormateado = formato.format(numero);

        // Agregar el símbolo manualmente
        return `COP ${numeroFormateado}`;

      } catch (error) {
        console.error("Error al formatear el número:", error.message);
        return numero; // Devolver el número original en caso de error
      }
    }
  }
}
</script>

<style>
.vs-input {
  width: 100%;
}
</style>